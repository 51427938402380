<!--
 * @Description: 我的导出列表
 * @Author: 小广
 * @Date: 2020-04-15 14:34:48
 * @LastEditors: 小广
 * @LastEditTime: 2020-04-15 14:47:13
 -->
<template>
  <div class="DataExportMyList-wrapper">
    <list-item :type="1"></list-item>
  </div>
</template>

<script>
import ListItem from './DataExportItem.vue'

export default {
  name: '',
  components: {
    'list-item': ListItem
  },
  data () {
    return {}
  },

  methods: {}
}
</script>

<style scoped lang="scss">
.DataExportMyList-wrapper {
  height: 100%;
}
</style>
